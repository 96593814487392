import React from 'react';
import { APP_TYPE, URL_APP_TYPE } from 'consts';
import { useTranslation } from 'react-i18next';
import { ValueOf } from 'types';

import styles from './styles.module.scss';

interface DefaultAdViewProps {
    appType: ValueOf<typeof URL_APP_TYPE>;
}

const DefaultAdView: React.FC<DefaultAdViewProps> = ({ appType }) => {
    const { t } = useTranslation('greetings');
    const withDescription = appType !== APP_TYPE.FLS;

    const getTitle = () => {
        switch (appType) {
            case URL_APP_TYPE[APP_TYPE.HX]:
            case URL_APP_TYPE[APP_TYPE.SF]:
                return t('Welcome to Haulage Exchange');
            case URL_APP_TYPE[APP_TYPE.FLS]:
                return t('Welcome to Freight Logistics Solution Carrier Portal');
            case URL_APP_TYPE[APP_TYPE.CXNA]:
            case URL_APP_TYPE[APP_TYPE.CXNA_EXPERDITER]:
                return t('Welcome to CX North America');
            case URL_APP_TYPE[APP_TYPE.CXNA_BROKER]:
                return t('Welcome to CX Broker');
            case URL_APP_TYPE[APP_TYPE.CXEURO]:
                return t('Welcome to CX Euro');
            case URL_APP_TYPE[APP_TYPE.CUSTOMER_PORTAL]:
                return t('Customer Portal');
            case URL_APP_TYPE[APP_TYPE.INTEGRA]:
            case URL_APP_TYPE[APP_TYPE.LENDER_PORTAL]:
                return t('Welcome to Integra');
            case URL_APP_TYPE[APP_TYPE.CXNA_CARRIER]:
                return t('Welcome to CX Carrier');
            default:
                return t('Welcome to Courier Exchange');
        }
    };

    return (
        <div className={styles.greetings}>
            <div className={styles.greetings__title}>{getTitle()}</div>
            {withDescription && (
                <>
                    <div className={styles.greetings__subtitle}>
                        <span>{t('Please use our secure login')}</span>
                        <br />
                        <span>{t('to access our platform')}.</span>
                    </div>
                    <div className={styles.greetings__text}>
                        <span>
                            {t(
                                'If this is the first time you are logging into Exchange, you will be presented with a step-by-step guide that will ask you various questions about you and your business',
                            )}
                            .
                            {t(
                                'Some of the details have already been entered, please check for accuracy and amend as required',
                            )}
                            .
                        </span>
                    </div>
                </>
            )}
        </div>
    );
};

export default DefaultAdView;
