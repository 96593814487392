import { APP_TYPE, URL_APP_TYPE } from 'consts';
import { collectError } from 'utils';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValueOf } from 'types';
import throttle from 'lodash.throttle';

import router, { FormRoutes, getParam } from '../../common/utils/routing';
import { checkIsLoggedIn } from '../../api/fx';

const getPageTitleByUrlAppType = (app: ValueOf<typeof URL_APP_TYPE>): string => {
    switch (app) {
        case URL_APP_TYPE[APP_TYPE.HX]:
            return 'Haulage Exchange Login';
        case URL_APP_TYPE[APP_TYPE.FSDF]:
        case URL_APP_TYPE[APP_TYPE.SF]:
            return 'Speedy Freight Login';
        case URL_APP_TYPE[APP_TYPE.FLS]:
            return 'FLS Carrier Portal Login';
        case URL_APP_TYPE[APP_TYPE.INTEGRA]:
        case URL_APP_TYPE[APP_TYPE.LENDER_PORTAL]:
            return 'Integra Login';
        case URL_APP_TYPE[APP_TYPE.CUSTOMER_PORTAL]:
            return 'Customer Portal Login';
        case URL_APP_TYPE[APP_TYPE.CXEURO]:
            return 'CX Euro Login';
        case URL_APP_TYPE[APP_TYPE.CXNA]:
        case URL_APP_TYPE[APP_TYPE.CXNA_EXPERDITER]:
            return 'CX North America Login';
        default:
            return 'Courier Exchange Login';
    }
};

export const useDocumentTitle = (): void => {
    const { t } = useTranslation();

    useEffect(() => {
        const type = getParam('app') as ValueOf<typeof URL_APP_TYPE>;
        const pathTitles = {
            [FormRoutes.login]: getPageTitleByUrlAppType(type),
            [FormRoutes.forgotPassword]: t('Forgot Password'),
            [FormRoutes.newPassword]: t('Change Password'),
            [FormRoutes.newEmail]: t('Change Email'),
        };

        const updateTitle = (pathname: string): void => {
            const title =
                pathname in pathTitles ? pathTitles[pathname as keyof typeof pathTitles] : pathTitles[FormRoutes.login];

            document.title = title;
        };

        router.subscribe(updateTitle);
        // eslint-disable-next-line
    }, []);
};

const pathToCheckSession: string[] = [FormRoutes.login, FormRoutes.forgotPassword];

export const useCheckSession = () => {
    const [isSessionChecked, setSessionChecked] = useState(false);

    useEffect(() => {
        if (pathToCheckSession.includes(router.pathname)) {
            checkIsLoggedIn()
                .then(() => {
                    window.location.assign('/');
                })
                .catch((error: Error) => {
                    setSessionChecked(true);
                    collectError(error);
                });
        } else {
            setSessionChecked(true);
        }
    }, []);

    return isSessionChecked;
};

export const useMobile = () => {
    const MOBILE_SCREEN_WIDTH_LESS_THEN = 1024;
    const THROTTLE_WAIT_FOR_EVENTS = 32;

    const isMobileScreenWidth = () => window.innerWidth <= MOBILE_SCREEN_WIDTH_LESS_THEN;
    const [isMobile, setIsMobile] = useState(isMobileScreenWidth);
    const observer = useRef<ResizeObserver>();

    useEffect(() => {
        if (window.ResizeObserver) {
            observer.current = new ResizeObserver((entries: ResizeObserverEntry[]) => {
                if (!Array.isArray(entries) || !entries.length) {
                    return;
                }

                throttle(() => setIsMobile(isMobileScreenWidth()), THROTTLE_WAIT_FOR_EVENTS);
            });
            observer.current.observe(document.body);
        }

        return () => {
            observer.current?.unobserve(document.body);
            observer.current = undefined;
        };
    });

    return isMobile;
};
