export var APP_TYPE = {
  CUSTOMER_PORTAL: 'customer_portal',
  CX: 'cx',
  CXEURO: 'cxeuro',
  CXNA: 'cxna',
  CXNA_BROKER: 'cxna_broker',
  CXNA_CARRIER: 'cxna_carrier',
  CXNA_EXPERDITER: 'cxna_expediter',
  FLS: 'fls',
  FSDF: 'fsdf',
  HX: 'hx',
  IFEX: 'ifex',
  INTEGRA: 'integra',
  LENDER_PORTAL: 'lender_portal',
  LENS: 'lens',
  SF: 'sf',
  TFX: 'tfx',
  TPN: 'tpn'
};
export default APP_TYPE;